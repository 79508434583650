<template>
  <div class="login">  
    <top ></top>
    <div class="center">
      <div class="background">
    <div class="Ulogin"> 
       <form action="" class="from">
        <div class="code">
          <div>
     <p  :class="{ active: isActive }" @click="codee">验证码登录 </p>
     <span class="yan"  v-if="type==1"></span>
     </div>
       <div><p :class="{ active: Active }" @click="uncode">账号登录</p>
        <span class="hao"  v-if="type==2"></span>
        </div> 
        </div>
        
        <div class="inputa" v-if="type==1" >
          <div class="opon">
        <input type="number" placeholder="手机号" v-model="phone" @keyup.enter="login">
        <el-button class="span" @click="getcode" :disabled="disabled">{{isRun?`${runTime}s`:"获取验证码"}}</el-button>
        </div>
          
        <input type="text"  placeholder="验证码" v-model="code" @keyup.enter="login">
        </div>
        <div class="input" v-if="type==2">
        <input type="number" placeholder="账号" v-model="phone" @keyup.enter="loginT" >
        
        <input type="password"  placeholder="密码" @keyup.enter="loginT" v-model="passWord">
        </div>
        <button type="button" class="button" @click="login" v-if="type==1" >登录</button>
        <button  type="button" class="button" @click="loginT" v-if="type==2"  >登录</button>
            <div class="agree">
          <!-- <input type="checkbox" name="" id="" :checked="checked" @click='sure'> -->
            <el-checkbox v-model="checked"  @click='sure' :checked="checked">我已阅读并同意 </el-checkbox>
            <a @click="platformServices" >平台服务协议</a> <span>及</span><a @click="privacy">隐私政策</a>
        </div>
         <Goodsure :addDialog="addDialog" @del="del" ref="child" @addsure="addsure"></Goodsure>
        <div class="create">
         <router-link to=/register class="a">   <div>没有账号？免费创建</div></router-link>
          <router-link to=/forget class="a">   <div>忘记账号或密码</div></router-link>
            
        </div>
       </form>
  </div>
          </div>
    </div>
    <Foo></Foo>
  </div>
  
</template>
<script>
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import loginApi from "../api/loginApi"
import  updataApi from '../api/updataApi'
import imApi from "../api/imApi"
import Goodsure from "../components/center/entre/goodsurelog.vue";
// import axios from "axios"
export default {
  data(){
   return{
      addDialog: false,
     //手机号
    phone:"",
    //验证码
    code:"",
    //密码
    passWord:"",
    type:1,
    isRun:false,
    runTime:"60",
    loginType:2,
      isActive: true,
      Active: false,
      sign:"",
      verifiedCode:"",
      token:"", 
       checked:true,
       disabled:false
   };
   
  },
  created(){
      // this.gain(this.iphon)
  },
  components: {
    Top,
    Foo,
    Goodsure
  },
  mounted(){
  },
  methods:{
     del(bool) {
      this.addDialog = bool;
    },
     addsure(bool) {
      this.addDialog = bool;
    },
    // 平台服务协议
    platformServices(){
       this.addDialog = true;
        this.$store.commit("intagreement",'platformServices')
         this.$refs.child.getAgreement()
    },
     
    // 隐私协议
    privacy(){
        this.addDialog = true;
   this.$store.commit("intagreement",'privacy')
    this.$refs.child.getAgreement()
    },
    //验证码登录
    sure(){
     this.checked=!this.checked
     
    },
     login(){
        const that = this
     
      if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
         return
      }
      if(this.checked==false){
       
        Message.error('点击阅读,才能登录');
        return
      }
   
    loginApi.getLogin({'loginType':2,'phone':this.phone,'passWord':this.passWord,"verifiedCode":this.code,"sign":this.sign},function(res){
      if(res.data.code!=200){
           Message.error(res.data.message);
      }
      if(res.data.code==200){
         that.$uweb.trackEvent("登录方式","登录","验证码登录")
             that.$store.commit('initLogin',JSON.stringify({
          token:res.data.result.token
        }))
       updataApi.getcurruserinfo('', function (res){
        
          that.$store.commit('initnickName',res.data.result.nickname)
        })  
         // 获取当前角色信息
              updataApi.getCurrUserRole("", (res) => {
            
                that.$store.commit("inituserInfostate",res.data.result.code)
              })
      
           Message({
          message: '登录成功，欢迎回来',
          type: 'success'
        });
         
       that.$router.push("/") 
      } 
       if(res.data.code==305){
          Message.error('验证码不正确');
      }

    })
     },
     //密码登录
     async loginT(){
        const that = this
         if(this.checked==false){
        Message.error('点击阅读,才能登录');
        return
      }
        if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
         return
      }
     await loginApi.getLogin({'loginType':1,'phone':this.phone,'passWord':this.passWord,"sign":this.sign,"verifiedCode":this.code},async function(res){
       if(res.data.code!=200){
           Message.error(res.data.message);
      }
      if(res.data.code==200){
         that.$uweb.trackEvent("登录方式","登录","密码登录")
         that.$store.commit('initLogin',JSON.stringify({
          token:res.data.result.token
        }))
           // 获取当前用户im账号密码
   await imApi.getCurrUser("",res=>{
      that.$store.commit('userInfo', res.data.result)
      	var options = {
				user: res.data.result.username,
				pwd: res.data.result.password,
				appKey: WebIM.config.appkey,
				apiUrl: 'https://a1.easecdn.com'
			};
			WebIM.conn.open(options);
   localStorage.setItem("userInfo", JSON.stringify({ userid: res.data.result.username, password: res.data.result.password }));
  })
        await updataApi.getcurruserinfo('', function (res){
        
          that.$store.commit('initnickName',res.data.result.nickname)
        })
          // 获取当前角色信息
              updataApi.getCurrUserRole("", (res) => {
               
                that.$store.commit("inituserInfostate",res.data.result.code)
             
              })
         Message({
          message: '登录成功，欢迎回来',
          type: 'success'
        });
        that.$router.push("/")
      }  
      
      
    })
      },

     //验证码登录
     codee(){
          this.type=1 
       this.Active=false
        this.isActive=true
     
     },
     //账号登录
     uncode(){
      this.type=2

       this.Active=true
        this.isActive=false
    
     
       
     },
     //获取验证码
     getcode(){
       if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
          this.phone=""
         return
      }
      else{
    loginApi.getLoginCode(this.phone,function(res){
      
       if(res.data.code!=200){
         window.alert(res.data.message)
       }
     })
         Message({
          message: '发送成功',
          type: 'success'
        });
       this.disabled = true;
         this.isRun = true;
      this.autoTime = setInterval(() => {
        if(this.runTime===0){
          this.runTime=60;
          this.isRun = false;
          this.disabled = false;
          clearInterval(this.autoTime)
           
          return
        }
        this.runTime--
        
      }, 1000);
       
      }
     
     },
     


     },
 

  
};
</script>
<style scoped>
/* @media screen and (max-width:1120px){
 .Ulogin{
width: 350px;
height: 410px;
background: #FFFFFF;
border-radius: 6px;
 border: 1px solid transparent;
 margin-top: 70px;
}
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    margin: 0; 
}
.center{
    width: 100%;
    height: 690px;
    /* border: 1px solid red; */
}
.Ulogin{
width: 350px;
height: 410px;
background: #FFFFFF;
border-radius: 6px;
 border: 1px solid transparent;
 margin-left:64%;
 margin-top: 70px;
}
.opon{
  display: flex;
  width: 390px;
  
}
.yan{
  /* border: 1px solid red; */
  width: 50px;
height: 1px;
background: #E94F55;
position: relative;
top: -0px;
left: 38px;
display: inline-block;
}
.hao{
  /* border: 1px solid red; */
  width: 50px;
height: 1px;
background: #E94F55;
position: relative;
top: 0px;
left: 28px;
display: inline-block;
}
.from{
width: 300px;
height: 360px;
margin-left: 30px;
}
.background{
  border: 1px solid transparent;
  height: 528px;
   background-image: url('~@/assets/login/background.png');
 background-position: 50%;

}
.code{
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 20px;
  margin-top: 20px;
  /* border: 1px solid red; */
}
.code p{
 cursor: pointer;
   width: 110px;
  height: 20px;
  font-size: 18px;
  margin-left: 16px;

  }
  
  .code p:nth-child(2){
    margin-left: 60px;
  }
  .code .active{
    color: red;
    }
    .inputa{
      margin-top: 30px;
    }
    .input{
      margin-top: 50px;
    }
    input{
      display: block;
      width: 280px;
     height: 47px;
      margin-top: 20px;
      border-radius: 3px;
      padding-left: 10px;
      outline: none;
      border: #ccc 1px solid;
    }
    .agree{
      cursor: pointer;
      font-size: 14px;
    /* border: 1px solid red; */
    position: relative;
    top: 20px;
    padding-bottom: 30px;
    
    }

    .agree a{
       color: rgb(64,158,255);
      margin-left: 2px;
      border-bottom: 1px solid rgb(79, 144, 218);
    }
   .agree span{
      color: rgb(64,158,255);
      margin-left: 2px;
     
    }
    .button{
      width: 294px;
height: 47px;
background: #E94F55;
border-radius: 6px;
      margin-top: 25px;
      /* background-color: rgb(233,79,85); */
      border: 0;
      color: white;
      text-align: center;
      font-size: 16px;
      line-height: 47px;
      cursor: pointer;
    }
    .create{
    display: flex;
      margin-top: 10px;
      width: 400px;
      height: 50px;
    color:red;
    }
    .create div{
      margin-top: 5px;
      cursor: pointer;
      font-size: 12px;
      margin-right: 40px;
    }
    .a{
      text-decoration: none;
      color: red;
    }
    .ab{
        text-decoration: none;
        color: black;
        margin-left: -70px;
    }
    .span{
      /* position: absolute; */
      /* top: 34%; */
      width: 104px;
      height: 38px;
      margin-top: 27px;
      font-size: 14px;
     
      color: rgb(121, 121, 121);
      /* border: 1px solid red; */
      cursor: pointer;
      border: 0;
      outline: 0;
      background: white;
      margin-left: -105px;
      display: inline-block;
    }
</style>